import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import { RichContent } from '../../elements';

// const Content = ({ content, className }) => (
//   <RichContent>
//     <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
//   </RichContent>
// );

RichContent.defaultProps = {
  as: 'p',
};

const options = {
  renderNode: {
    'embedded-asset-block': (node, next) => (
      <PreviewCompatibleImage
        style={{ maxWidth: '100%' }}
        imageInfo={{ image: node.data.target.fields.file['en-US'].url }}
      />
    ),
  },
};

const Content = ({
  document, content, className, ...rest
}) => {
  const html = documentToReactComponents(document, options);
  return document ? (
    <RichContent {...rest} as="div" className={className}>
      {html}
    </RichContent>
  ) : (
    <RichContent
      {...rest}
      as="div"
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

export default Content;
